import React, { Component } from "react";
import transformUrl from "../../modules/transformUrl";

class CartItem extends Component {

  incrementQuantity = async (event) => {
    const targetId = event.target.id;
    const itemId = parseInt(targetId.split('-')[1], 10);
    const body = { itemId, quantity: this.props.data.quantity + 1 };
    await this.props.updateCart(body);
  }

  decrementQuantity = async (event) => {
    const targetId = event.target.id;
    const itemId = parseInt(targetId.split('-')[1], 10);
    const body = { itemId, quantity: this.props.data.quantity - 1 };
    await this.props.updateCart(body);
  }

  render() {
    const { data } = this.props;
    const { id, name, price, googleDriveUrl } = data;
    console.log("CartItem => props", this.props);
    return <>
      <div>
        <div className="product">
          <img className="cart-image" alt="productImage" src={transformUrl(googleDriveUrl)}></img>
          <div className="product-details">
            <div>
              <h1>{name}</h1>
              <div className='price-tag'>
                <p>Price : &#8377; {price}</p>
                <div className="cart-buttons">
                  <button id={"dec-" + id} onClick={this.decrementQuantity}>-</button>
                  <span>{this.props.data.quantity}</span>
                  <button id={"inc-" + id} onClick={this.incrementQuantity} >+</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  }
}

export default CartItem;