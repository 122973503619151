import React, { Component } from "react";
import { Link } from "react-router-dom";
import createRequest from "../../modules/createRequest";
import transformUrl from "../../modules/transformUrl";
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonText: "Add to cart",
      buttonStyle: "btn-purchase",
      deleteButtonStyle: "hide",
      priceTagStyle: "price-tag",
    };
  }

  addToCart = async (event) => {
    this.setState(
      {
        buttonText: "Added to cart",
        buttonStyle: "btn-purchase btn-purchased",
        deleteButtonStyle: "btn-purchase btn-delete",
      },
      async () => {
        const targetId = event.target.id;
        const addItemId = parseInt(targetId.split("-")[1], 10);
        const body = { itemId: addItemId };
        const response = await createRequest("post", "/addToCart/", body);
        console.log(response);
      }
    );
  };

  removeFromCart = async (event) => {
    this.setState(
      {
        buttonText: "Add to cart",
        buttonStyle: "btn-purchase",
        deleteButtonStyle: "hide",
      },
      async () => {
        const targetId = event.target.id;
        const deleteId = parseInt(targetId.split("-")[1], 10);
        const body = { itemId: deleteId };
        const response = await createRequest("delete", "/removeFromCart/", body);
        console.log(response);
      }
    );
  };
  render() {
    const { data } = this.props;
    const { id, name, price, googleDriveUrl } = data;
    console.log(googleDriveUrl);
    const { buttonText, buttonStyle, priceTagStyle, deleteButtonStyle } = this.state;
    console.log("Product => props", this.props);
    return (
      <>
        <div>
          <div className="product">
            <Link to={"products/" + id}>
              <img className="product-image" alt="productImage" src={transformUrl(googleDriveUrl)}></img>
            </Link>
            <div className="product-details">
              <div>
                <h1>{name}</h1>
                <div className={priceTagStyle}>
                  <p>Price : &#8377; {price}</p>
                  <div>
                    <button id={"add-" + id} className={buttonStyle} onClick={(event) => this.addToCart(event)}>
                      {buttonText}
                    </button>
                    <button id={"delete-" + id} className={deleteButtonStyle} onClick={(event) => this.removeFromCart(event)}>
                      Remove
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Product;
