import React, { Component } from "react";
import { Link, Route, Routes } from "react-router-dom";
import Home from "../Home";
class CartItem extends Component {

  incrementQuantity = async (event) => {
    const targetId = event.target.id;
    const itemId = parseInt(targetId.split('-')[1], 10);
    const body = { itemId, quantity: this.props.data.quantity + 1 };
    await this.props.updateCart(body);
  }

  decrementQuantity = async (event) => {
    const targetId = event.target.id;
    const itemId = parseInt(targetId.split('-')[1], 10);
    const body = { itemId, quantity: this.props.data.quantity - 1 };
    await this.props.updateCart(body);
  }

  render() {
    const { data } = this.props;
    const { id, cartItems, subTotal } = data;
    console.log("Order => props", this.props);
    return <>
      <div className="order">
        <p>Order Id : <Link to={id}>{id}</Link></p>
        <p>Sub total : {subTotal}</p>
        <p>Number items : {cartItems.length}</p>
        <Link to={id}>View order details</Link>
      </div>
    </>
  }
}

export default CartItem;