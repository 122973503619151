import { Component } from "react";
import NavBar from "../NavBar";

class ShoppingContainer extends Component {

  constructor(props) {
    super(props);
    this.state = { products: [] }
  }

  componentDidMount = async () => {
    const response = await fetch("http://annamapps.in:8000", { mode: 'cors' });
    const data = await response.json();
    this.setState({ products: data.products });
    console.log("ShoppingContainer");
  }

  render() {
    const { products } = this.state;
    console.log("ShoppingContainer => State", this.state);
    return <>
      <NavBar products={products} />
    </>
  }
}

export default ShoppingContainer;