import React from "react";
import { useParams } from "react-router-dom";

import createRequest from "../../modules/createRequest";
import transformUrl from "../../modules/transformUrl";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class ProductDetails extends React.Component {
  componentDidMount = async () => {
    let { id } = this.props.params;
    console.log(id);
    const response = await createRequest('get', "/products/" + id);
    console.log("Order =>", response);
    this.setState({ product: response });
  }

  render() {
    if (this.state !== null) {
      const { product } = this.state;
      return <>
        <div>
          <h1>Product Details </h1>
          <img alt="Item" src={transformUrl(product.googleDriveUrl)} className="product-details-image"></img>
          <h2>{product.name}</h2>
          <h2>Price: {product.price}</h2>
        </div>
      </>
    } else {
      return <div></div>
    }

  }
}

export default withParams(ProductDetails);