import React from "react";
import { useParams } from "react-router-dom";

import createRequest from "../../modules/createRequest";
import transformUrl from "../../modules/transformUrl";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class OrderDetails extends React.Component {
  componentDidMount = async () => {
    let { id } = this.props.params;
    console.log(id);
    const response = await createRequest('get', "/orders/" + id);
    console.log("Order =>", response);
    this.setState({ order: response });
  }

  render() {
    if (this.state !== null) {
      const { order } = this.state;
      const { cartItems, subTotal } = order;
      return <>
        <div><h1>Sub total : {subTotal}</h1></div>
        {cartItems.map((item) => <>
          <div key={item.id} className="order-details-item">
            <img alt="Item" src={transformUrl(item.googleDriveUrl)} className="cart-image"></img>
            <p>Quantity : {item.quantity}</p>
            <p>Price : {item.price}</p>
          </div>
        </>)}
      </>
    } else {
      return <div></div>
    }

  }
}

export default withParams(OrderDetails);