
const createRequest = async (method, route, body) => {

  const options = {
    method: method,
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };

  const url = "http://annamapps.in:8000" + route;
  const response = await fetch(url, options);
  const responseData = await response.json();
  return responseData;

}

export default createRequest;
