import { Component } from "react";
import createRequest from "../../modules/createRequest";
import Product from "../Product";

class Home extends Component {
  componentDidMount = async () => {
    const response = await createRequest("get", "/");
    console.log("\n\nHome Mounted", response);
    this.setState({ products: response.products });
    console.log(this.props.location);
  };

  render() {
    if (this.state === null) {
      return <div className="products"></div>;
    } else {
      const { products } = this.state;
      return (
        <div className="products">
          {products.map((product) => (
            <Product key={product.id} data={product}></Product>
          ))}
        </div>
      );
    }
  }
}

export default Home;
