import { Component } from "react";
import Order from "../Order";
import createRequest from "../../modules/createRequest";

class Cart extends Component {

  componentDidMount = async () => {
    const orders = await createRequest('get', "/orders/");
    console.log("\n\nOrders Mounted", orders);
    this.setState({ orders: orders });
  }

  render() {
    if (this.state === null) {
      return <div></div>
    } else {
      const { orders } = this.state;
      return <div className="orders">
        {orders.map((order) => <Order key={order.id} data={order} ></Order>)}
      </div>
    }
  }

}

export default Cart;