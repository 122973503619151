import React, { Component } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Home from "../Home";
import Cart from "../Cart";
import Orders from "../Orders"
import OrderDetails from "../OrderDetails";
import ProductDetails from "../ProductDetails";

class NavBar extends Component {


  handleToggleMenuClick = (event) => {
    console.log(event.target.nextElementSibling.classList.toggle("display"));
  }

  render() {
    console.log("NavBar => Props", this.props);
    const navBar =
      <BrowserRouter>
        <div className="menu">
          <Link to='#' onClick={(event) => this.handleToggleMenuClick(event)}>&#9776; Menu</Link>
          <div className="nav-bar">
            <Link to='/' className="home">Home</Link>
            <Link to='/cart'>Cart</Link>
            <Link to='/orders'>Orders</Link>
          </div>
        </div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/orders/:id" element={<OrderDetails />} />
          <Route exact path="/products/:id" element={<ProductDetails />} />
        </Routes>
      </BrowserRouter>
    return navBar
  }
}

export default NavBar;
