import { Component } from "react";
import CartItem from "../CartItem";
import createRequest from "../../modules/createRequest";
import processCartData from "../../modules/processCartData";

class Cart extends Component {

  componentDidMount = async () => {
    const response = await createRequest('get', "/cart/");
    console.log("\n\nCart Mounted");
    const cartInfo = processCartData(response);
    console.log("Cart info", cartInfo);
    this.setState({ cart: response, cartInfo });
  }

  updateCartItemQuantity = async (body) => {
    const response = await createRequest('put', "/updateCartItemQuantity/", body);
    const cartInfo = processCartData(response);
    this.setState({ cart: response, cartInfo });
    console.log("Updated Quantity Info", cartInfo);
  }

  placeOrder = async () => {
    const response = await createRequest('put', "/placeOrder/");
    console.log("Order placed", response);
    this.setState({ cartInfo: { subTotal: 0 } });
  }

  render() {
    if (this.state === null) {
      return <div>Cart is empty</div>
    } else if (this.state.cartInfo.subTotal === 0) {
      return <div>
        <h1>Cart is empty</h1>
      </div>
    } else {
      const { cartItems, totalItems, subTotal } = this.state.cartInfo;
      return <div className="cart">
        <div className="cart-items">
          {cartItems.map((cartItem) => <CartItem key={cartItem.id} data={cartItem} updateCart={this.updateCartItemQuantity}></CartItem>)}
        </div>
        <div className="cart-bill">
          <h1>Sub Total({totalItems} items): Rs. {subTotal}/-</h1>
          <button onClick={this.placeOrder}>Place Order</button>
        </div>
      </div>
    }
  }

}

export default Cart;