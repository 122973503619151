import './App.css';
import ShoppingContainer from './components/ShoppingContainer';

function App() {
  return (
    <div>
      <ShoppingContainer></ShoppingContainer>
    </div>
  );
}

export default App;
